<template>
  <div>
    <preloader v-if="status.getting" />

    <v-card v-if="!status.getting" class="shadow-lg">
        <v-card-text>
          <v-simple-table v-if="prices.length">
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount</th>
                <th class="text-center">Subscribers</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="price in refinedPrices" :key="price.id">
                <td class="text-capitalize">{{ price.description }}</td>
                <td>
                  ${{ price.unit_amount | amountNoneDecimal }} <span class="text-uppercase">{{ price.currency }}</span> / {{ price.interval_count }} {{ pluralize(price.interval, price.interval_count) }}
                </td>
                <td class="text-center">{{ $store.getters['subscription/getSubscriberCount'](price) }}</td>
                <td class="text-right">
                  <v-btn @click="downloadCsv(price)" small depressed>
                    Download CSV
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import _orderBy from 'lodash/orderBy'
import _capitalize from 'lodash/capitalize'
import jsonexport from 'jsonexport'
import pluralize from 'pluralize'
import moment from 'moment'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      pluralize
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      prices: state => state.subscription.packages,
      data: state => state.subscription.data,
      status: state => state.subscription.status,
    }),

    refinedPrices() {
      return _orderBy(this.prices, (price) => {
        return parseInt(price.metadata.order)
      }, 'asc')
    }
  },  

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('subscription', ['getCustomers', 'getPackages']),

    /*------------------------------------------------------------------------------
     * DOWNLOAD CSV
     *----------------------------------------------------------------------------*/
    downloadCsv(price) {
      let subscribers = this.$store.getters['subscription/getSubscribers'](price)
      let data = []

      subscribers.forEach(sub => {
        let user = this.$store.getters['users/userData'](sub.customer)

        if (Object.keys(user).length) {
          data.push({
            'FIRST NAME': user.firstName,
            'LAST NAME': user.lastName,
            'EMAIL': user.email,
            'JOB TITLE': user.jobTitle,
            'ACCOUNT CREATED': moment(user.createdAt).format('MM/DD/YYYY'),
            'SUBSCRIPTION TYPE': _capitalize(price.description)
          })
        }
      })

      jsonexport(data, (err, csv) => {
        if (err) return console.error(err)
        else {
          let filename = this.dates ? `report_${moment(this.dates[0]).format('DDMMYYYY')}_to_${moment(this.dates[1]).format('DDMMYYYY')}.csv` : 'report.csv'

          var link = document.createElement("a")
          let csvContent = "data:text/csv;charset=utf-8," + csv
          var encodedUri = encodeURI(csvContent)
          link.setAttribute("href", encodedUri)
          link.setAttribute("download", filename)
          document.body.appendChild(link)
          link.click()
        }
      })
    }
  },
  
  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getPackages()
    this.getCustomers()
  }
}
</script>