<template>
  <div class="flex-grow-1 pb-12">
    <AdminTitle title="Reporting"></AdminTitle>

    <div>
      <employer />
    </div>
  </div>
</template>

<script>
import employer from './EmployerReport'

export default {
  metaInfo: {
    title: 'Reporting'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      view: 'Employers'
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    employer
  }
}
</script>